import { ListDetailNewForm, ListDetailUpdateForm } from "./list-detail-new-form";


export function newListDetail({
    icon,
    mdIcon,
    filter,
    title,

    defaultValues,
    finalFormMapper = data => data,

    newForm: NewForm,
    updateForm: UpdateForm,

    isAvailable,
    canDelete = true,
    canSave = true,
    skipCreateBtn,
    closeOnSave,
    clearStateOnSave,

    skipInlinePadding,
    fullScreenSideView,
    fullScreenSideViewUpdateForm,
    noTitle,
    saveLabel,
    deleteLabel,
    renderOptions,
    rawPanelFunc,

    openDetailOnCreate,

    onPreSave,
}) {

    return {
        icon, filter, isAvailable,
        isListMode: true,
        openItemInSideView: true,
        skipCreateBtn,

        sideViews: {
            fullScreen: fullScreenSideView,
            fullScreenUpdateForm: fullScreenSideViewUpdateForm,

            newForm: {
                openDetailOnCreate,
                Component: (props => (
                    <ListDetailNewForm title={"New " + title} {...{ defaultValues, finalFormMapper, canSave, skipInlinePadding, noTitle, saveLabel, onPreSave }} {...props}>
                        {params => <NewForm {...props} {...params} />}
                    </ListDetailNewForm>
                ))
            },

            updateForm: {
                Component: (props => (
                    <ListDetailUpdateForm icon={mdIcon} title={title} {...{ defaultValues, finalFormMapper, canDelete, canSave, skipInlinePadding, noTitle, deleteLabel, renderOptions, rawPanelFunc, closeOnSave, clearStateOnSave }} {...props}>
                        {(form, data, onUpdate, params) => <UpdateForm {...props} {...params} form={form} data={data} onUpdate={onUpdate} />}
                    </ListDetailUpdateForm>
                ))
            }
        },
    }
}